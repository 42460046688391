export default {
  white: '#FFFFFF',
  black: '#000000',
  primaryPink: '#CC041B',
  secondaryPink: '#FFA0A5',
  primaryCharcoal: '#232323',
  secondaryCharcoal: '#666666',
  primaryGray: '#B0B4BA',
  secondaryGray: '#979797',
  borderGray: '#E2E3E2',
  softBorderGray: '#E0E0E0',
  backgroundGray: '#F4F4F4',

  softText: '#444444',
  firmText: '#414141',

  disabledGray: '#EEEEEE',
  mutedGray: '#C4C4C4',
  softGray: '#DADCDF',

  whitesmoke: '#F8F9FA',
  borderWhitesmoke: '#EBEBED',

  placeholderText: '#a3a3a3',

  alertRed: '#FF3B30', // iOS badge color
  alertBlue: '#0599D9',

  subtleGray: '#D8D8D8',

  backgroundIOS: '#fafafa',

  transparentDark: 'rgba(0, 0, 0, 0.5)',
}
